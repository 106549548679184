export type AbsolutePath = `/${string}`;

export const Paths = {
  Dashboard: '/dashboard',
  Login: '/login',
  Logout: '/logout',
  Pricing: '/pricing',
  Reporting: '/reporting',
  Root: '/',
  Settings: '/settings',
  Signup: '/signup',
  Welcome: '/welcome',
} as const satisfies Record<string, AbsolutePath>;

export const DefaultPostLoginPath = Paths.Dashboard;

export function createPath(base: AbsolutePath, ...segments: string[]): string {
  return `${base}${segments.join('/')}`;
}
