import {useEffect, useState} from 'react';

interface UseDelay {
  hasDelayElapsed: boolean;
}
export function useDelay(delayMillis: number): UseDelay {
  const [hasDelayElapsed, setHasDelayElapsed] = useState<boolean>(false);
  useEffect(() => {
    const timeout = globalThis.setTimeout(() => setHasDelayElapsed(true), delayMillis);
    return () => {
      clearTimeout(timeout);
    };
  }, [delayMillis, setHasDelayElapsed]);
  return {hasDelayElapsed};
}
