import {createContext, useContext} from 'react';
import {DefaultThemeName, ThemeName} from '../themes';

interface ThemeNameState {
  setThemeName: (themeName: ThemeName) => void;
  themeName: ThemeName;
}

/* c8 ignore start */
export const ThemeNameContext = createContext<ThemeNameState>({
  setThemeName: () => {
    // Defaults to no-op; will be initialized in CurrentThemeProvider
  },
  themeName: DefaultThemeName,
});
/* c8 ignore stop */

export function useThemeName(): ThemeNameState {
  return useContext(ThemeNameContext);
}
