import {Theme, ThemeOptions, createTheme} from '@mui/material/styles';
import {ThemeNames} from '#common/theme/themes';

export type ThemeName = (typeof ThemeNames)[number];
export const DefaultThemeName: ThemeName = 'light';

/*
 * Light and dark themes taken from Material defaults: https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
 * Note: 'dark', 'light', and 'contrastText' variants are automatically calculated from 'main' color.
 * To cutomize themes see:
 * - https://mui.com/material-ui/customization/color/#picking-colors
 * - https://m2.material.io/resources/color/
 * - https://bareynol.github.io/mui-theme-creator/
 * - https://m2.material.io/inline-tools/color/
 */

const LightTheme: ThemeOptions = {
  palette: {
    contrastThreshold: 4.5, // To meet minimum contrast of 4.5:1 as defined in WCAG 2.1 Rule 1.4.3 use >= 4.5
    error: {
      main: '#d32f2f',
    },
    info: {
      main: '#0288d1',
    },
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#9c27b0',
    },
    success: {
      main: '#2e7d32',
    },
    tonalOffset: 0.2,
    warning: {
      main: '#ed6c02',
    },
  },
};

const DarkTheme: ThemeOptions = {
  palette: {
    contrastThreshold: 4.5, // To meet minimum contrast of 4.5:1 as defined in WCAG 2.1 Rule 1.4.3 use >= 4.5
    error: {
      main: '#f44336',
    },
    info: {
      main: '#29b6f6',
    },
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#ce93d8',
    },
    success: {
      main: '#66bb6a',
    },
    tonalOffset: 0.2,
    warning: {
      main: '#ffa726',
    },
  },
};

export const themeConfigurations: Record<ThemeName, ThemeOptions> = {
  dark: DarkTheme,
  light: LightTheme,
};

// Themes created with default MUI locale (en-US):
export const themes: Record<ThemeName, Theme> = ThemeNames.reduce(
  (allThemes, themeName) => {
    allThemes[themeName] = createTheme(themeConfigurations[themeName]);
    return allThemes;
  },
  {} as Record<ThemeName, Theme>,
);
