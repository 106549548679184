import {useEffect, useState} from 'react';
import {Theme, createTheme} from '@mui/material';
import {useCurrentLocale} from '../../i18n/hooks/useCurrentLocale';
import {MaybeUndefined} from '#common/types';
import {ThemeName, themeConfigurations, themes} from '../themes';

// MUI's default locale is English (United States)
// https://mui.com/material-ui/guides/localization/
const DefaultMuiLocale = 'en-US';

export function useTheme(themeName: ThemeName): MaybeUndefined<Theme> {
  const {code: localeCode} = useCurrentLocale();
  const [theme, setTheme] = useState<MaybeUndefined<Theme>>();

  useEffect(() => {
    const getMuiLocale = async () => {
      if (localeCode === DefaultMuiLocale) {
        setTheme(themes[themeName]);
        return;
      }
      const locales = await findMuiLocales(localeCode);
      if (locales.length) {
        setTheme(createTheme(themeConfigurations[themeName], ...locales));
        /* c8 ignore start */
      } else {
        // Fall back to default locale
        setTheme(themes[themeName]);
      }
      /* c8 ignore stop */
    };
    getMuiLocale();
  }, [localeCode, setTheme, themeName]);

  return theme;
}

function toMuiLocale(localeCode: string): string {
  // MUI uses locale codes like enUS in its locale imports, while our detected locales are of the form en-US
  return localeCode.replaceAll('-', '');
}

async function findMuiLocales(localeCode: string): Promise<object[]> {
  const muiLocaleImports = [import('@mui/material/locale'), import('@mui/x-date-pickers/locales')];

  const muiLocaleCode = toMuiLocale(localeCode);
  const muiLocalePrefix = muiLocaleCode.substring(0, 2);
  const [muiCoreLocales, muiDatePickerLocales] = await Promise.all(muiLocaleImports);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let coreLocale = (muiCoreLocales as any)[muiLocaleCode];
  if (!coreLocale) {
    // Try to find a MUI locale with the same prefix (e.g. if detected locale is es, find esES):
    const muiLocaleByPrefix = Object.keys(muiCoreLocales).find((muiLocale) => muiLocale.startsWith(muiLocalePrefix));
    if (muiLocaleByPrefix) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      coreLocale = (muiCoreLocales as any)[muiLocaleByPrefix];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let datePickersLocale = (muiDatePickerLocales as any)[muiLocaleCode];
  if (!datePickersLocale) {
    // Try to find a MUI locale with the same prefix (e.g. if detected locale is es, find esES):
    const muiLocaleByPrefix = Object.keys(muiDatePickerLocales).find((muiLocale) =>
      muiLocale.startsWith(muiLocalePrefix),
    );
    if (muiLocaleByPrefix) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      datePickersLocale = (muiDatePickerLocales as any)[muiLocaleByPrefix];
    }
  }

  return [coreLocale, datePickersLocale].filter((locale) => !!locale);
}
