import {Backdrop, Box, CircularProgress, CircularProgressProps} from '@mui/material';
import {ComponentWithoutChildren, PropsWithoutChildren} from '../react/types';
import {useDelay} from './hooks/useDelay';
import {useSynchronizedAnimation} from './hooks/useSynchronizedAnimation';

export type LoadingProps = PropsWithoutChildren<CircularProgressProps>;

export const Loading: ComponentWithoutChildren<LoadingProps> = (props: LoadingProps) => {
  // Note: can't translate label beause loading components are used prior to i18n initialization
  const label = props['aria-label'] ?? 'Loading';
  const ref = useSynchronizedAnimation(label);
  return (
    <Box alignItems="center" display="flex" height="100%" justifyContent="center" width="100%">
      <CircularProgress {...props} aria-label={label} ref={ref} />
    </Box>
  );
};

interface DelayedFullScreenLoadingProps extends FullScreenLoadingProps {
  renderDelayMillis?: number;
}
export const DelayedFullScreenLoading: ComponentWithoutChildren<DelayedFullScreenLoadingProps> = ({
  renderDelayMillis = 1000,
  ...props
}: DelayedFullScreenLoadingProps) => {
  const {hasDelayElapsed} = useDelay(renderDelayMillis);
  if (!hasDelayElapsed) {
    return null;
  }
  return <FullScreenLoading {...props} />;
};

export interface FullScreenLoadingProps extends Omit<LoadingProps, 'size'> {
  transitionDurationMillis?: number;
  visible?: boolean;
}
export const FullScreenLoading: ComponentWithoutChildren<FullScreenLoadingProps> = ({
  color = 'inherit',
  transitionDurationMillis,
  visible = true,
  ...props
}: FullScreenLoadingProps) => {
  return (
    <Backdrop
      open={visible}
      sx={{color: (theme) => theme.palette.grey[400], zIndex: (theme) => theme.zIndex.drawer + 1}}
      transitionDuration={transitionDurationMillis}
    >
      <Loading {...props} color={color} size="10vmax" thickness={2} />
    </Backdrop>
  );
};
