import {SvgIcon} from '@mui/material';

export const FirebaseLogo = (
  <SvgIcon>
    <svg viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          gradientTransform="matrix(2.67 0 0 -2.67 317.23 -1808)"
          gradientUnits="userSpaceOnUse"
          id="d"
          x1="-108.63"
          x2="-58.56"
          y1="-692.24"
          y2="-742.31"
        >
          <stop offset="0" stopColor="#fff" stopOpacity=".1"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="b" x1="56.9" x2="48.9" y1="102.54" y2="98.36">
          <stop offset="0" stopColor="#a52714"></stop>
          <stop offset=".4" stopColor="#a52714" stopOpacity=".5"></stop>
          <stop offset=".8" stopColor="#a52714" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="c" x1="90.89" x2="87.31" y1="90.91" y2="87.33">
          <stop offset="0" stopColor="#a52714" stopOpacity=".8"></stop>
          <stop offset=".5" stopColor="#a52714" stopOpacity=".21"></stop>
          <stop offset="1" stopColor="#a52714" stopOpacity="0"></stop>
        </linearGradient>
        <clipPath id="a">
          <path d="M143.41 47.34a4 4 0 00-6.77-2.16L115.88 66 99.54 34.89a4 4 0 00-7.08 0l-8.93 17-22.4-41.77a4 4 0 00-7.48 1.28L32 150l57.9 32.46a12 12 0 0011.7 0L160 150z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          d="M32 150L53.66 11.39a4 4 0 017.48-1.27l22.4 41.78 8.93-17a4 4 0 017.08 0L160 150z"
          fill="#ffa000"
        ></path>
        <path d="M106 9L0 0v192l32-42L106 9z" fill="url(#b)" opacity=".12"></path>
        <path d="M106.83 96.01l-23.3-44.12L32 150l74.83-53.99z" fill="#f57c00"></path>
        <path d="M0 0h192v192H0z" fill="url(#c)" opacity=".2"></path>
        <path d="M160 150L143.41 47.34a4 4 0 00-6.77-2.16L32 150l57.9 32.47a12 12 0 0011.7 0z" fill="#ffca28"></path>
        <path
          d="M143.41 47.34a4 4 0 00-6.77-2.16L115.88 66 99.54 34.89a4 4 0 00-7.08 0l-8.93 17-22.4-41.77a4 4 0 00-7.48 1.28L32 150h-.08l.07.08.57.28L115.83 67l20.78-20.8a4 4 0 016.78 2.16l16.45 101.74.16-.1zM32.19 149.81L53.66 12.39a4 4 0 017.48-1.28l22.4 41.78 8.93-17a4 4 0 017.08 0l16 30.43z"
          fill="#fff"
          fillOpacity=".2"
        ></path>
        <path
          d="M101.6 181.49a12 12 0 01-11.7 0l-57.76-32.4-.14.91 57.9 32.46a12 12 0 0011.7 0L160 150l-.15-.92z"
          fill="#a52714"
          opacity=".2"
        ></path>
        <path
          d="M143.41 47.34a4 4 0 00-6.77-2.16L115.88 66 99.54 34.89a4 4 0 00-7.08 0l-8.93 17-22.4-41.77a4 4 0 00-7.48 1.28L32 150l57.9 32.46a12 12 0 0011.7 0L160 150z"
          fill="url(#d)"
        ></path>
      </g>
    </svg>
  </SvgIcon>
);
