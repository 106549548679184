import {TOptions} from 'i18next';
import {useTranslation as useI18nextTranslation} from 'react-i18next';
import {NamespacePrefixedKey, NamespaceType, TranslationFunction} from '../types';

type UseTranslationResult = {
  translate: TranslationFunction;
};

export function useTranslation(namespaces?: NamespaceType | NamespaceType[]): UseTranslationResult {
  const {t} = useI18nextTranslation(namespaces, {useSuspense: true});
  return {
    translate: (key: NamespacePrefixedKey, options?: TOptions) => {
      if (options) {
        return t(key, options);
      }
      return t(key);
    },
  };
}
