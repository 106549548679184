/* eslint import/no-named-as-default-member: 0 */
import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import {initReactI18next} from 'react-i18next';
import {DefaultLocale, extractLanguageFromLocale} from './locales';
import {Namespace, Namespaces} from './namespaces';

const LocalBackend = resourcesToBackend((locale, namespace, callback) => {
  const language = extractLanguageFromLocale(locale);
  import(`../locales/${language}/${namespace}.json`)
    .then(({default: resources}) => callback(null, resources))
    .catch((error) => callback(error, null));
});

i18next
  .use(initReactI18next)
  .use(LocalBackend)
  .init({
    defaultNS: Namespace.common,
    fallbackLng: DefaultLocale.code,
    interpolation: {
      escapeValue: false,
    },
    load: 'languageOnly', // en-GB and en-US will both resolve to en
    ns: Namespaces,
    react: {
      useSuspense: true,
    },
  });

// When The language changes, set the document direction
i18next.on('languageChanged', (locale) => {
  const direction = i18next.dir(locale);
  document.dir = direction;
});

export const i18n = i18next;
