import {Suspense, lazy} from 'react';
import {Route, Routes} from 'react-router-dom';
import {ComponentWithoutChildren} from '../react/types';
import {DelayedFullScreenLoading} from '../common/Loading';
import {Paths} from './paths';
import {Root} from '../main/Root';
import {makeRelative} from './routeUtils';
import {withErrorBoundary} from '../common/ErrorBoundary';

/* c8 ignore start */
// Not easy to exhaustively test all routes and lazy imports

const RootWithErrorBoundary = withErrorBoundary(Root);

// Most component imports for routes should be lazy to avoid pulling unnecessary dependencies into the main chunk
const Dashboard = withErrorBoundary(lazy(() => import('../pages/dashboard/Dashboard')));
const FirebaseOutlet = withErrorBoundary(lazy(() => import('./FirebaseOutlet')));
const Login = withErrorBoundary(lazy(() => import('../auth/Login')));
const Logout = withErrorBoundary(lazy(() => import('../auth/Logout')));
const NotFound = withErrorBoundary(lazy(() => import('../main/NotFound')));
const Pricing = withErrorBoundary(lazy(() => import('../pages/pricing/Pricing')));
const PrivateAppOutlet = withErrorBoundary(lazy(() => import('./PrivateAppOutlet')));
const PrivateLayout = withErrorBoundary(lazy(() => import('../layout/private/PrivateLayout')));
const PublicAppOutlet = withErrorBoundary(lazy(() => import('./PublicAppOutlet')));
const PublicLayout = withErrorBoundary(lazy(() => import('../layout/public/PublicLayout')));
const Reporting = withErrorBoundary(lazy(() => import('../pages/reporting/Reporting')));
const Settings = withErrorBoundary(lazy(() => import('../pages/settings/Settings')));
const Signup = withErrorBoundary(lazy(() => import('../auth/Signup')));
const Welcome = withErrorBoundary(lazy(() => import('../pages/welcome/Welcome')));

export const AppRoutes: ComponentWithoutChildren = () => {
  return (
    <Suspense fallback={<DelayedFullScreenLoading />}>
      <Routes>
        <Route element={<PublicAppOutlet />}>
          <Route element={<PublicLayout withoutContent />}>
            <Route
              element={
                <RootWithErrorBoundary>
                  <Welcome />
                </RootWithErrorBoundary>
              }
              index
            />
            <Route element={<Welcome />} path={Paths.Welcome} />
          </Route>
          <Route
            element={
              <PublicLayout
                contentProps={{
                  bgcolor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                  m: 2,
                }}
              />
            }
          >
            <Route element={<Pricing />} path={Paths.Pricing} />
          </Route>
        </Route>
        <Route element={<FirebaseOutlet />}>
          <Route element={<Logout />} path={Paths.Logout} />
          <Route element={<PublicAppOutlet />}>
            <Route
              element={
                <PublicLayout contentProps={{bgcolor: 'lightgrey'}} publicTopBarProps={{hideLoginButton: true}} />
              }
            >
              <Route element={<Login />} path={Paths.Login} />
            </Route>
            <Route
              element={
                <PublicLayout contentProps={{bgcolor: 'lightgrey'}} publicTopBarProps={{hideSignupButton: true}} />
              }
            >
              <Route element={<Signup />} path={Paths.Signup} />
            </Route>
          </Route>
          <Route element={<PrivateAppOutlet />} path="*">
            <Route element={<PrivateLayout />}>
              <Route element={<Dashboard />} path={makeRelative(Paths.Dashboard)} />
              <Route element={<Reporting />} path={makeRelative(Paths.Reporting)} />
              <Route element={<Settings />} path={makeRelative(Paths.Settings)} />
              <Route element={<NotFound />} path="*" />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

/* c8 ignore stop */
