import {useCallback, useMemo} from 'react';
import {MaybeNull} from '#common/types';
import {UseStorageOptions, UseStorageResult} from './useStorage';
import {useLocalStorage} from './useLocalStorage';

export function useLocalStorageDate(
  key: string,
  initialValue?: Date,
  options?: UseStorageOptions,
): UseStorageResult<Date> {
  const [date, setDate] = useLocalStorage<number>(key, initialValue?.getTime(), options);
  const currentValue = useMemo(() => deserializeDate(date), [date]);
  const setter = useCallback((value: MaybeNull<Date>) => setDate(serializeDate(value)), [setDate]);
  return [currentValue, setter];
}

function deserializeDate(date: MaybeNull<number>): MaybeNull<Date> {
  if (!date) {
    return null;
  }
  const parsed = new Date(date);
  if (isNaN(parsed.getTime())) {
    return null;
  }
  return parsed;
}

function serializeDate(date: MaybeNull<Date>): MaybeNull<number> {
  return date?.getTime() ?? null;
}
