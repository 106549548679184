import {ReactNode, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Link,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import {ComponentWithoutChildren} from '../react/types';
import {FirebaseLogo} from './FirebaseLogo';
import {SimpleThemeProvider} from '../theme/ThemeProvider';

export const FirebaseConfigHelp: ComponentWithoutChildren = () => {
  const [stepIndex, setStepIndex] = useState<number>(0);
  return (
    <SimpleThemeProvider theme="dark">
      <Container maxWidth="md" sx={{mt: 2}}>
        <Card sx={{width: '100%'}} variant="elevation">
          <CardHeader
            avatar={FirebaseLogo}
            title="Complete Firebase setup steps"
            titleTypographyProps={{variant: 'h5'}}
          />
          <Divider variant="middle" />
          <CardContent>{Steps[stepIndex].content}</CardContent>
          <Divider variant="middle" />
          <CardActions>
            <Box display="flex" flexDirection="row" justifyContent="space-between" mx={1} width="100%">
              <Button disabled={stepIndex === 0} onClick={() => setStepIndex(stepIndex - 1)} variant="contained">
                Previous
              </Button>
              <Stepper activeStep={stepIndex} sx={{mx: 2}}>
                {Steps.map((step, index) => (
                  <Step key={`step-${index}`}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Button
                disabled={stepIndex === Steps.length - 1}
                onClick={() => setStepIndex(stepIndex + 1)}
                variant="contained"
              >
                Next
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Container>
    </SimpleThemeProvider>
  );
};

interface StepLabelAndContent {
  content: ReactNode;
  label: string;
}

const Steps: StepLabelAndContent[] = [
  {
    content: (
      <ol>
        <li>
          Sign into the{' '}
          <Link href="https://console.firebase.google.com" rel="noopener" target="_blank" underline="hover">
            Firebase Console
          </Link>
        </li>
        <li>Create a new Firebase project</li>
      </ol>
    ),
    label: 'Create',
  },
  {
    content: (
      <ol>
        <li>Navigate to Firebase Project Settings ⇒ General</li>
        <li>
          Scroll down to the &quot;Your apps&quot; section, and click the web app icon: <code>&lt;/&gt;</code>
        </li>
        <li>Choose an app nickname, check the option to set up Firebase Hosting, and register the app</li>
        <li>Skip the Add Firebase SDK step; Firebase Quickstart is already integrated with the SDK</li>
        <li>Install the Firebase CLI</li>
        <li>Skip the deployment to Firebase Hosting for now</li>
      </ol>
    ),
    label: 'Configure',
  },
  {
    content: (
      <ol>
        <li>Under Product Categories in the left side menu, navigate to Build ⇒ Authentication</li>
        <li>Click Get Started</li>
        <li>Under &quot;Native providers&quot;, click Email/Password, enable via toggle, and save</li>
        <li>Click &quot;Add new provider&quot;</li>
        <li>Under &quot;Additional providers&quot;, click Google, enable via the toggle, and save</li>
      </ol>
    ),
    label: 'Auth',
  },
  {
    content: (
      <ol>
        <li>Under Product Categories in the left side menu, navigate to Build ⇒ Firestore Database</li>
        <li>Click &quot;Create database&quot;</li>
        <li>Ensure that &quot;Start in production mode&quot; is selected, and click the Next button</li>
        <li>Choose a region (multi-region recommended), and click the Enable button</li>
        <li>Navigate to Firestore Database ⇒ Rules</li>
        <li>
          Replace existing rules with the contents of the <code>./firestore.rules</code> file from Firebase Quickstart
        </li>
        <li>Publish the rules changes by clicking the Publish button</li>
      </ol>
    ),
    label: 'Firestore',
  },
  {
    content: (
      <ol>
        <li>Navigate to Firebase Project Settings ⇒ General</li>
        <li>Scroll down to the web app you created earlier</li>
        <li>Under &quot;SDK setup and configuration&quot; choose the Config option</li>
        <li>
          Copy the <code>firebaseConfig</code> props into the <code>./src/firebase/config.ts</code> file
        </li>
        <li>As soon as you save the Firebase config file, this setup guide should disappear</li>
        <li>Enjoy your Firebase Quickstart app!</li>
      </ol>
    ),
    label: 'React',
  },
];
