import {I18nextProvider} from 'react-i18next';
import {ComponentWithRequiredChildren, PropsWithRequiredChildren} from '../react/types';
import {i18n} from './i18n';

type I18nProviderProps = PropsWithRequiredChildren;

export const I18nProvider: ComponentWithRequiredChildren<PropsWithRequiredChildren> = ({
  children,
}: I18nProviderProps) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
