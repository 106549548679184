import {InstallOutcome} from '../types';
import {UseStorageResult} from '../../common/hooks/useStorage';
import {useLocalStorage} from '../../common/hooks/useLocalStorage';

const InstallOutcomeKey = 'appInstallOutcome';

export type UseStoredAppInstallOutcome = UseStorageResult<InstallOutcome>;

export function useStoredAppInstallOutcome(): UseStoredAppInstallOutcome {
  return useLocalStorage<InstallOutcome>(InstallOutcomeKey);
}
