import {environment} from '../config';

export function clearLocalStorage() {
  clearStorage(globalThis.localStorage);
}

export function clearSessionStorage() {
  clearStorage(globalThis.sessionStorage);
}

function clearStorage(storage: Storage) {
  storage.clear();
}

export function isRunningInUnitTest(): boolean {
  return environment === 'test';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
export function noop<A = void, R = void>(_arg: A): R {
  return null as unknown as R;
}

export function noopFn<A = void, R = void>(): (arg: A) => R {
  return noop as (arg: A) => R;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noArgNoop(): void {}

export function range(start: number, stop: number, step = 1): number[] {
  return Array.from({length: (stop - start) / step + 1}, (_, i) => start + i * step);
}

export function sleep(delayMillis: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(resolve, delayMillis));
}
