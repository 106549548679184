import {Locale} from 'date-fns';
import {enUS} from 'date-fns/locale/en-US';

export const DefaultLocale: Locale = enUS;

export function extractLanguageFromLocale(locale: string): string {
  const delimiterIndex = locale.indexOf('-', 2);
  if (delimiterIndex > 0) {
    return locale.substring(0, delimiterIndex);
  }
  return locale;
}
