import {useOptionalCurrentUser} from './useOptionalCurrentUser';
import {UserDocument} from '../UserDocument';

export function useCurrentUser(): UserDocument {
  const maybeCurrentUser = useOptionalCurrentUser();
  /* c8 ignore start */
  if (!maybeCurrentUser) {
    throw new Error('User must be defined prior to calling useCurrentUser');
  }
  /* c8 ignore stop */
  return maybeCurrentUser;
}
