import {BrowserRouter} from 'react-router-dom';
import {AppInstallFunctionsProvider} from '../pwa/AppInstallFunctions';
import {AppLoading} from './AppLoading';
import {AppRoutes} from '../routes/AppRoutes';
import {ComponentWithoutChildren} from '../react/types';
import {ErrorBoundary} from '../common/ErrorBoundary';
import {FirebaseConfigHelp} from '../firebase/FirebaseConfigHelp';
import {I18nProvider} from '../i18n/I18nProvider';
import {configuration} from '../config';
import {isRunningInUnitTest} from '../tests/testUtils';

// Note: ErrorBoundary uses translations so must be a child of I18nProvider
export const App: ComponentWithoutChildren = () => {
  /* c8 ignore start */
  if (!Object.keys(configuration.firebase).length && !isRunningInUnitTest()) {
    // TODO remove this block once firebase configuration is complete
    return <FirebaseConfigHelp />;
  }
  /* c8 ignore stop */
  return (
    <AppLoading>
      <I18nProvider>
        <ErrorBoundary>
          <AppInstallFunctionsProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </AppInstallFunctionsProvider>
        </ErrorBoundary>
      </I18nProvider>
    </AppLoading>
  );
};
