import {Dispatch, useCallback, useState} from 'react';
import {MaybeNull} from '#common/types';

export interface UseStorageOptions {
  storeInitialValue?: boolean;
}

export type UseStorageResult<T> = [MaybeNull<T>, Dispatch<MaybeNull<T>>];

export function useStorage<T>(
  storage: Storage,
  key: string,
  initialValue?: T,
  options?: UseStorageOptions,
): UseStorageResult<T> {
  const storeOrDelete = useCallback(
    (value: MaybeNull<T>) => {
      if (value === null) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, JSON.stringify(value));
      }
    },
    [key, storage],
  );

  const [storedValue, setStoredValue] = useState<MaybeNull<T>>(() => {
    /* c8 ignore start */
    if (typeof window === 'undefined') {
      return initialValue;
    } /* c8 ignore stop */
    try {
      const value = storage.getItem(key);
      if (typeof value === 'string') {
        return JSON.parse(value);
      } else if (options?.storeInitialValue && initialValue !== undefined) {
        storeOrDelete(initialValue);
        return initialValue;
      } else {
        return initialValue;
      }
      /* c8 ignore start */
    } catch (_error) {
      if (initialValue !== undefined) {
        storeOrDelete(initialValue);
      }
      return initialValue;
    } /* c8 ignore stop */
  });

  const setValue = useCallback(
    (value: MaybeNull<T>) => {
      setStoredValue(value);
      /* c8 ignore start */
      if (typeof window !== 'undefined') {
        try {
          storeOrDelete(value);
        } catch (_error) {
          // Failed to serialize or write to storage
        }
      } /* c8 ignore stop */
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setStoredValue, storeOrDelete],
  );

  return [storedValue, setValue];
}
