import {Navigate} from 'react-router-dom';
import {ComponentWithRequiredChildren, PropsWithRequiredChildren} from '../react/types';
import {MaybeNull} from '#common/types';
import {Paths} from '../routes/paths';
import {useLastAccessTime} from '../auth/hooks/useLastAccessTime';

const RecentLoginMillis = 7 * 24 * 60 * 60 * 1_000; // 1 week

export const Root: ComponentWithRequiredChildren = ({children}: PropsWithRequiredChildren) => {
  const [lastLoginTime] = useLastAccessTime();
  const hasRecentLogin = isRecentLogin(lastLoginTime);

  if (hasRecentLogin) {
    return <Navigate to={Paths.Login} />;
  }

  return <>{children}</>;
};

function isRecentLogin(lastLoginTime: MaybeNull<Date>): boolean {
  return Date.now() - (lastLoginTime?.getTime() ?? 0) <= RecentLoginMillis;
}

export default Root; // Default export required for react lazy
