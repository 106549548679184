import {createContext, useContext} from 'react';

interface AppLoadingState {
  isAppLoading: boolean;
  setAppLoading: (appLoading: boolean) => void;
}

/* c8 ignore start */
export const AppLoadingContext = createContext<AppLoadingState>({
  isAppLoading: true,
  setAppLoading: (_isLoading: boolean) => {
    // Defaults to no-op; will be initialized in AppLoadingProvider
  },
});
/* c8 ignore stop */

export function useAppLoading(): AppLoadingState {
  return useContext(AppLoadingContext);
}
